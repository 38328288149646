<app-navbar></app-navbar>

<div class="bg-gradient-to-b from-gray-50 to-gray-100 enhance-image px-4 sm:px-6 lg:px-8 min-h-screen thankyoupage">
  <div class="max-w-7xl mx-auto py-16">
    <!-- Thank You Message -->
    <div *ngIf="!showFeedbackForm" class="bg-white rounded-2xl shadow-xl p-12 flex flex-col items-center transition-all duration-300 hover:shadow-2xl border-2 border-orange-400">
      <div class="mb-8">
        <svg class="w-24 h-24 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
        </svg>
      </div>

      <h1 class="text-4xl font-extrabold mb-4 text-center bg-clip-text text-transparent bg-gradient-to-r from-orange-500 to-pink-500">
        Thank You!
      </h1>

      <p class="text-center text-xl text-gray-600 mb-8">
        We appreciate your feedback. Your input helps us improve our service.
      </p>

      <button (click)="goBack()" class="px-8 py-3 bg-gradient-to-r from-orange-500 to-pink-500 text-white rounded-full font-semibold hover:opacity-90 transition-opacity">
        Return to Image Enhancer
      </button>
    </div>

    <!-- Feedback Form -->
    <div *ngIf="showFeedbackForm" class="bg-white rounded-2xl shadow-xl p-12 transition-all duration-300 hover:shadow-2xl border-2 border-orange-400">
      <h2 class="text-3xl font-bold mb-8 text-center text-orange-500">Leave a Review</h2>
      
      <form (submit)="submitFeedback($event)" class="space-y-6">
        <!-- Name Field -->
        <div class="space-y-2">
          <label for="name" class="block text-sm font-medium text-gray-700">Name</label>
          <input 
            type="text" 
            id="name" 
            name="name" 
            [(ngModel)]="feedbackData.name"
            class="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-orange-500 focus:border-orange-500"
            required
          >
        </div>

        <!-- Email Field -->
        <div class="space-y-2">
          <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
          <input 
            type="email" 
            id="email" 
            name="email" 
            [(ngModel)]="feedbackData.email"
            class="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-orange-500 focus:border-orange-500"
            required
          >
        </div>

        <!-- Message Field -->
        <div class="space-y-2">
          <label for="message" class="block text-sm font-medium text-gray-700">Message</label>
          <textarea 
            id="message" 
            name="message" 
            [(ngModel)]="feedbackData.message"
            rows="4"
            class="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-orange-500 focus:border-orange-500"
            required
          ></textarea>
        </div>

        <div class="flex justify-between space-x-4">
          <button 
            type="button" 
            (click)="goBack()" 
            class="px-6 py-2 border-2 border-orange-500 text-orange-500 rounded-full font-semibold hover:bg-orange-50 transition-colors">
            Cancel
          </button>
          <button 
            type="submit" 
            class="px-6 py-2 bg-gradient-to-r from-orange-500 to-pink-500 text-white rounded-full font-semibold hover:opacity-90 transition-opacity">
            Submit Feedback
          </button>
        </div>
      </form>
    </div>
  </div>
</div>