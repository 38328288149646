import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { AboutComponent } from './shared/about/about.component';
import { ServicesComponent } from './shared/services/services.component';
import { PortfolioComponent } from './shared/portfolio/portfolio.component';
import { ReviewComponent } from './shared/review/review.component';
import { TermUseComponent } from './shared/term-use/term-use.component';
import { UrlApiComponent } from './pages/url-api/url-api.component';
import { ThankYouComponent } from './pages/thank-you/thank-you.component';
const routes: Routes = [
  { path: '', loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) },
  { path: 'auth', loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule) },
  { path: 'user-profile', component: UserProfileComponent },
  {path:"about",component:AboutComponent},
  {
    path:'service',
    component:ServicesComponent
  },
  {
    path:'portfolio',
    component:PortfolioComponent
  },
  {
    path:'review',
    component:ReviewComponent
  },
  {
    path:'term-use',
    component:TermUseComponent
  },
  {
    path: 'test',
    component: UrlApiComponent
  },
  { path: 'thank-you', component: ThankYouComponent },
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
