import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule, FormsModule]
})
export class ThankYouComponent implements OnInit {
  showFeedbackForm: boolean = false;
  feedbackData = {
    name: '',
    email: '',
    message: '',
    rating: 0
  };

  constructor(private router: Router) {}

  ngOnInit() {
    // Check if we came from closing the form
    const navigation = this.router.getCurrentNavigation();
    if (navigation?.extras?.state?.['fromClose']) {
      this.showFeedbackForm = true;
    }
  }

  goBack() {
    this.router.navigate(['/']);
  }

  submitFeedback(event: Event) {
    event.preventDefault();
    // Handle feedback submission
    console.log('Feedback submitted:', this.feedbackData);
    this.showFeedbackForm = false;
    // You can add API call here if needed
    this.router.navigate(['/thank-you'], { state: { fromSubmit: true }});
  }
}
